    /* HIPPO: '#00AFAF',
    DARK_HIPPO: '#008686',
    LIGHT_HIPPO: '#80D7D7',

    BLUE: '#0064AF',
    DARK_BLUE: '#003686',
    LIGHT_BLUE: '#4D93C7',

    GREEN: '#00AF64',
    DARK_GREEN: '#008636',
    LIGHT_GREEN: '#80D7B2',

    ORANGE: '#FAAF00',
    DARK_ORANGE: '#F89600',
    LIGHT_ORANGE: '#FDD780',

    PURPLE: '#9600FF',
    DARK_PURPLE: '#7900FF',
    LIGHT_PURPLE: '#E0B3FF',

    RED: '#D32F2F',
    DARK_RED: '#B71212',
    LIGHT_RED: '#E99797',

    WHITE: 'white',
    
    GREY: 'rgba(0,0,0,0.6)',
    DARK_GREY: 'rgba(0,0,0,0.8)',
    LIGHT_GREY: 'rgba(0,0,0,0.2)',
    LIGHTER_GREY: 'rgba(0,0,0,0.05)', */
    


.choice-correct {
    color: white;
    border-color: #008636;
    background-color: #00AF64;
    cursor: default;
}

.choice-selected {
    border-color: #008686;
    background-color: #80D7D7;
    cursor: default;
}

.choice-default {
    border-color: 'rgba(0,0,0,0.2)';
}
@media (hover: hover) {
    .choice-default:hover {
        border-color: #80D7D7;
        box-shadow: 1px 1px 3px #80D7D7;
        color: #80D7D7;
    }
}

.choice-default:active {
    border-color: #008686;
    color: #008686;
    box-shadow: 1px 1px 2px #008686;
}

.choice-disabled {
    border-color: rgba(0,0,0,0.2);
    cursor: default;
}

.choice-rightanswer {
    color: #00AF64;
    border: 2px solid;
    border-color: #00AF64;
    cursor: default;
}

.choice-wrong {
    color: white;
    border-color: #B71212;
    background-color: #D32F2F;
    cursor: default;
}
