.choice-correct {
  color: white;
  border: 1px solid #008636;
  background-color: #00af64;
  cursor: default;
}

.choice-selected {
  border: 1px solid #008686;
  background-color: #80d7d7;
  cursor: default;
}

.choice-default {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
@media (hover: hover) {
  .choice-default:hover {
    border: 1px solid #80d7d7;
    box-shadow: 1px 1px 3px #80d7d7;
    color: #80d7d7;
  }
}

.choice-default:active {
  border: 1px solid #008686;
  color: #008686;
  box-shadow: 1px 1px 2px #008686;
}

.choice-disabled {
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: default;
}

.choice-rightanswer {
  color: #00af64;
  border: 2px solid #00af64;
  cursor: default;
}

.choice-wrong {
  color: white;
  border: 1px solid #b71212;
  background-color: #d32f2f;
  cursor: default;
}
